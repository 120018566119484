import { getAccountSettings } from "./account-settings"
import { getAnalyticsSettings } from "./analytics"
import { getApiIntegrationDashboardSettings } from "./api-integration-settings"
import { getCicoTableSettings } from "./cico"
import { getCohortsSettings } from "./cohorts"
import { getCohortEmployeeSettings } from "./cohort-employees"
import { getCompanyAbsenceTypesSettings } from "./company-absence-types"
import { getCompanyEquipmentSettings } from "./company-equipment"
import { getCompanyGroupsSettings } from "./company-groups"
import { getCostCodeAccrualSettings } from "./cost-code-accrual"
import { getGuestFormShareSettings } from "./guest-form-shares"
import { getCostCodeControlsDashboardSettings } from "./cost-code-controls-settings"
import { getEmployeesSettings } from "./employees"
import { getEnhancedTandMDemoSettings } from "./enhanced-t-and-m"
import { getEquipmentTrackingSettings } from "./equipment-tracking"
import { getFieldFormsDashboardSettings } from "./field-forms-dashboard"
import { getPcoNumberSettings } from "./pco-numbers"
import { getMaterialSettings } from "./materials"
import { getModifyProductionSettings } from "./modify-production"
import { getProductionTrackingSettings } from "./production-tracking"
import { getProjectCostCodesSettings } from "./project-cost-codes"
import { getProjectEmployeesSettings } from "./project-employees"
import { getProjectEquipmentSettings } from "./project-equipment"
import { getProjectMaterialSettings } from "./project-materials"
import { getProjectsListViewSettings } from "./projects-list-view"
import { getShiftExtrasDashboardSettings } from "./shift-extras-dashboard"
import { getTkExportFormatSettings } from "./tk-export-formats"
import { getUnitRateTrackerSettings } from "./unit-rate-tracker"
import { getWeeklyTimeCardSettings } from "./weekly-time-card"
import { getTimekeepingModifiersDashboardSettings } from "./timekeeping-modifiers-dashboard"
import { getTimekeepingStatusSettings } from "./timekeeping-statuses"

const settingsMap = {
    accountSettings: getAccountSettings,
    analyticsSettings: getAnalyticsSettings,
    apiIntegrationSettings: getApiIntegrationDashboardSettings,
    cohorts: getCohortsSettings,
    cohortEmployees: getCohortEmployeeSettings,
    companyAbsenceTypes: getCompanyAbsenceTypesSettings,
    companyEquipment: getCompanyEquipmentSettings,
    companyGroups: getCompanyGroupsSettings,
    costCodeAccrual: getCostCodeAccrualSettings,
    costCodes: getProjectCostCodesSettings,
    companyCrewTypes: getCostCodeControlsDashboardSettings,
    employees: getEmployeesSettings,
    enhancedTandMDemo: getEnhancedTandMDemoSettings,
    equipment: getProjectEquipmentSettings,
    equipmentTracking: getEquipmentTrackingSettings,
    fieldForms: getFieldFormsDashboardSettings,
    gcReferenceNumbers: getPcoNumberSettings,
    guestFormShares: getGuestFormShareSettings,
    materials: getMaterialSettings,
    modifyProduction: getModifyProductionSettings,
    productionTracking: getProductionTrackingSettings,
    projects: getProjectsListViewSettings,
    projectEmployees: getProjectEmployeesSettings,
    projectMaterials: getProjectMaterialSettings,
    shiftExtras: getShiftExtrasDashboardSettings,
    timekeepingModifiers: getTimekeepingModifiersDashboardSettings,
    timekeepingStatuses: getTimekeepingStatusSettings,
    timelineEntryVersions: getCicoTableSettings,
    tkExportFormats: getTkExportFormatSettings,
    unitRateTracker: getUnitRateTrackerSettings,
    weeklyTimeCard: getWeeklyTimeCardSettings,
}

const getMenuInfoForDashboardList = dashboardList => {
    return dashboardList.map(demoDashboardKey => {
        const settings = settingsMap[demoDashboardKey]({})
        return {
            settingsKey: demoDashboardKey,
            path: settings.navId,
            tableName: settings.tableName,
        }
    })
}
// TODO: we are adding "equipmentTracking" here as a beta dashboard route and
// in navigation/menu-options.jsx, we are filtering out the equipment tracking
// route. We need to figure out how to deal with this. The page won't load
// without it.
const demoDashboardList = ["enhancedTandMDemo"]
const betaDashboardList = ["equipmentTracking"]
export const demoDashboardMenuList = getMenuInfoForDashboardList(demoDashboardList)
export const betaDashboardMenuList = getMenuInfoForDashboardList(betaDashboardList)
export const getSettingsForDashboard = (
    dashboardName,
    featureFlags,
    currentProject,
    currentUser,
    currentProjectIds,
    sourceData,
    config
) => {
    return settingsMap[dashboardName](
        featureFlags,
        currentProject,
        currentUser,
        currentProjectIds,
        sourceData,
        config
    )
}
