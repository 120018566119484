import { format } from "date-fns"
import { getFlagEnabled } from "../getFlagValue"
import { STORED_DATE_ONLY_FORMAT } from "./constants"
import { getAsDate, setCellData } from "./ts-utils"
import { JsonPointer as jsonpointer } from "json-ptr"
import cloneDeep from "clone-deep"

/**
 * Same as getGroupKeyInfo (below), but takes ag grid apis for params.
 **/
export const getGroupKeyInfoFromApis = (gridApi, columnApi, referenceableData) => {
    const cell = gridApi.getFocusedCell()
    const rowNode = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
    const column = cell.column
    const pivotColumns = columnApi.getPivotColumns()
    return getGroupKeyInfo(rowNode, column, pivotColumns, referenceableData)
}

/**
 * Given the row and column details for a cell, this function returns an array of
 * objects which describe how to filter a data set down to the rows that would be
 * aggregated into the cell. There is one element in the array for every row or column
 * group level that this cell is under. Each element is an object with three properties:
 * colID - the primary column id for this group row or pivot column ex: employee, cost code, date, etc.
 * value - the value associated with this group row or pivot column
 * keyCreator - a function that will return a grouping key given a value
 * This array can then be passed to getSourceDataForGroupKeyInfo to filter a data set.
 **/
export const getGroupKeyInfo = (rowNode, column, pivotColumns, referenceableData) => {
    // find the column filters for this cell
    const columnFilterValues = column.colDef.pivotKeys
    const columnFilters = pivotColumns.reduce(function (total, ele, i) {
        if (columnFilterValues[i]) {
            let value
            if (ele.colDef.keyToValue) {
                value = ele.colDef.keyToValue({
                    key: columnFilterValues[i],
                    referenceableData: referenceableData,
                    colDef: ele.colDef,
                })
            } else {
                value = columnFilterValues[i]
            }
            total.push({
                colDef: ele.colDef,
                value: value,
            })
        }
        return total
    }, [])

    // find the row filters for this cell
    let currentRowNode = rowNode
    const rowFilters = []
    while (currentRowNode && currentRowNode.level >= 0) {
        const colDef = currentRowNode.rowGroupColumn ? currentRowNode.rowGroupColumn.colDef || {} : {}
        let value
        if (colDef.keyToValue && (!colDef.fakeKey || (colDef.fakeKey && colDef.fakeKey !== currentRowNode.key))) {
            value = colDef.keyToValue({
                key: currentRowNode.key,
                referenceableData: referenceableData,
                colDef: colDef,
            })
        } else if (colDef.fakeKey && colDef.fakeKey === currentRowNode.key) {
            value = colDef.fakeValue
        } else {
            value = currentRowNode.key
        }
        rowFilters.push({
            colDef: colDef,
            value: value,
        })
        currentRowNode = currentRowNode.parent
    }

    return columnFilters.concat(rowFilters.reverse())
}

/**
 * Given a data set and group key info (see getGroupKeyInfo), this function returns the data set filtered
 * down to whatever rows falls into all the groups described in the group key info.
 **/
export const getSourceDataForGroupKeyInfo = (sourceData, groupKeyInfo, context) => {
    const data = {}
    for (const resource in sourceData) {
        data[resource] = sourceData[resource]?.filter(
            row => !row.dummy && rowFitsAllKeys(row, groupKeyInfo, context)
        )
    }
    if (
        getFlagEnabled("WA-8225-fancy-find-copy-paste-bug") &&
        context.fancySearchTerm.category &&
        context.fancySearchTerm.term &&
        context.fancySearchTerm.doFilter
    ) {
        return filterData(data, context)
    } else return data
}

/**
 * Filters a set of AG Grid source data by whatever fancy search parameters exist.
 * @param data The source data for the ag grid table
 * @param context The AG Grid context
 * @returns {*} A subset of the data which matches the filter criteria (if any)
 */
export const filterData = (data, context) => {
    let filteredData = {}

    if (
        context.fancySearchTerm?.doFilter &&
        getFlagEnabled("WA-7151-fancy-filter-before-action-buttons") &&
        ((Array.isArray(context.fancySearchTerm?.term) && context.fancySearchTerm?.term.length) ||
            context.fancySearchTerm.term)
    ) {
        Object.entries(data).map(([resource, records]) => {
            filteredData[resource] = records?.filter(record =>
                searchCellData(
                    { context },
                    { [resource]: [record] },
                    context.fancySearchTerm.category,
                    context.fancySearchTerm.term
                )
            )
        })
    } else {
        filteredData = cloneDeep(data)
    }

    return filteredData
}

/**
 * Given a single row and group key info (see getGroupKeyInfo), this function returns a boolean
 * indicating whether the rows fall into all the groups described in the group key info.
 **/
export const rowFitsAllKeys = (row, groupKeyInfo, context) => {
    return !groupKeyInfo.some(keyInfoInstance => {
        const keyCreator = keyInfoInstance.colDef.keyCreator
            ? keyInfoInstance.colDef.keyCreator
            : params => params.value
        let value
        if (keyInfoInstance.colDef.valueGetter) {
            value = keyInfoInstance.colDef.valueGetter({
                data: row,
                colDef: keyInfoInstance.colDef,
                context,
            })
        } else {
            value = row[keyInfoInstance.colDef.field]
        }
        // if a group key really just filters some other row, don't filter out blanks
        if (
            keyInfoInstance.colDef.editableValueGetter &&
            !keyInfoInstance.colDef.editableValueGetter({ data: row, context })
        ) {
            return false
        }
        const rowKey = keyCreator({ value: value })
        const lockedColumnKey = keyCreator({ value: keyInfoInstance.value })
        return rowKey !== lockedColumnKey
    })
}

// Helper method to get the diff of the old and new dates and apply them to the desired keys
const updateDates = (oldRow, newRow, startKey, stopKey) => {
    const wsDate = newRow["date"]
    const startTime = getAsDate(oldRow[startKey])
    const startDate = format(startTime, STORED_DATE_ONLY_FORMAT)
    const diffMs = getAsDate(wsDate) - getAsDate(startDate)
    newRow[startKey] = new Date(startTime.getTime() + diffMs).toISOString()
    newRow[stopKey] = new Date(getAsDate(oldRow[stopKey]).getTime() + diffMs).toISOString()
}

/**
 * Given a source data object, group key info (see getGroupKeyInfo) and the source data colDefs,
 * this function creates a duplicate of the source data, but for every row, the columns which are
 * in the group key info are updated to match the group key info and the columns that have a
 * overrideValueOnGroupPaste are set to that value. In other words, this returns data that matches
 * sourceData, except it will show up in the pivot cell described by groupKeyInfo, and any columns
 * which have overrideValueOnGroupPaste set will default to that value instead of the copied value.
 **/
export const getSourceDataForPivotPaste = (sourceData, groupKeyInfo, colDefs) => {
    const dataWithUpdatedGroups = {}
    const colDefsWithOverrideValue = colDefs.filter(colDef => colDef.overrideValueOnGroupPaste)
    for (const resourceName in sourceData) {
        dataWithUpdatedGroups[resourceName] = sourceData[resourceName].map(row => {
            const newRow = JSON.parse(JSON.stringify(row))
            groupKeyInfo.forEach(keyInfoInstance => {
                setCellData(newRow, keyInfoInstance.colDef, keyInfoInstance.value)
            })
            colDefsWithOverrideValue.forEach(colDef => {
                setCellData(newRow, colDef, colDef.overrideValueOnGroupPaste)
            })
            // Special case for start/stop times being copied from cell to cell.
            // Not using colDefsWithOverrideValue because the start/stop time colDefs don't exist
            // in the main table, only in the edit modal's table.
            if (row["start_time"] && row["stop_time"]) {
                updateDates(row, newRow, "start_time", "stop_time")
            }
            if (row["shift_start_time"] && row["shift_end_time"]) {
                updateDates(row, newRow, "shift_start_time", "shift_end_time")
            }
            return newRow
        })
    }
    return dataWithUpdatedGroups
}

export const getFiltersFromGrouping = params => {
    const pivotColumns = params.columnApi.getPivotColumns()
    const groupKeyInfo = getGroupKeyInfo(params.node, params.column, pivotColumns, params.context.referenceableData)
    const filters = {}
    groupKeyInfo.forEach(info => {
        if (info.colDef.filterQueryParam) {
            const keyCreator = info.colDef.keyCreator ? info.colDef.keyCreator : params => params.value
            if (Array.isArray(info.colDef.filterQueryParam)) {
                info.colDef.filterQueryParam.forEach(filter => {
                    filters[filter] = keyCreator({ value: info.value })
                })
            } else {
                filters[info.colDef.filterQueryParam] = keyCreator({
                    value: info.value,
                })
            }
        }
    })

    return filters
}

/**
 * Search through cell data according to the table's find rules which exist in the settings file.
 * @param params Parameters from the ag grid table (mostly the context is required)
 * @param selectedCellData Data for the current cell
 * @param searchCategory What type of data are we filtering
 * @param searchTerm What are the terms that we're filtering on
 * @returns {boolean|*} Whether the cell meets the find criteria or not
 */
export const searchCellData = (params, selectedCellData, searchCategory, searchTerm) => {
    const findRules = params.context.settings.otherSettings?.findConfig
    const { referenceableData } = params.context
    // If this table doesn't have find rules, bail early

    if (!findRules || !findRules[searchCategory]) return false

    // If we don't have a search term, we bail early unless the search config
    // forces to automatically filter empty values

    if (!searchTerm && !findRules[searchCategory].autoFilterEmptyValues) return false

    // We might be searching through multiple entity types (i.e. Absence and
    // Timekeeping Entry) for a match on something like Employee Name. If any
    // of those entities referenced in the cell match, it's a hit

    return findRules[searchCategory].resources.some(resource => {
        if (!selectedCellData[resource.sourceType]) return
        // Grab a couple config parameters in case we're searching on a
        // referenced object
        const referenceResource = resource.reference?.resource
        const referenceFields = resource.reference?.fields

        return selectedCellData[resource.sourceType]?.some(entity => {
            // We use the cell's source entities by default
            let searchObject = entity
            let searchFields = [resource.sourceField]
            // Skip any dummy cells that are custom column/row headers

            if (searchObject.dummy) return false

            // If the find configuration indicates it's a referenced field,
            // we swap the search object and fields

            if (referenceResource && referenceableData[referenceResource]) {
                const refId = jsonpointer.get(entity, resource.sourceField)
                searchObject = referenceableData[referenceResource][refId]
                searchFields = referenceFields
            }
            const concatRegex = /concat\((\/\w+), (\/\w+)\)/
            // Check that any of the fields match for that entity. We might have a list of terms
            // if it is an enum picker, otherwise it'll be a string
            return Array.isArray(searchTerm)
                ? searchFields.some(fieldName => {
                      if (jsonpointer.has(searchObject, fieldName) && jsonpointer.get(searchObject, fieldName)) {
                          return searchTerm.some(st =>
                              jsonpointer.get(searchObject, fieldName).toLowerCase().includes(st.toLowerCase())
                          )
                      }
                  })
                : searchFields.some(fieldName => {
                      let value
                      // See if any of the search fields are concatenations
                      const match = fieldName.match(concatRegex)
                      if (match) {
                          const field1 = match[1]
                          const field2 = match[2]
                          if (
                              jsonpointer.has(searchObject, field1) &&
                              jsonpointer.has(searchObject, field2) &&
                              jsonpointer.get(searchObject, field1) &&
                              jsonpointer.get(searchObject, field2)
                          )
                              value = `${jsonpointer.get(searchObject, field1)} ${jsonpointer.get(
                                  searchObject,
                                  field2
                              )}`
                      } else if (
                          jsonpointer.has(searchObject, fieldName) &&
                          (jsonpointer.get(searchObject, fieldName) || findRules[searchCategory].keepEmptyValues)
                      ) {
                          value = jsonpointer.get(searchObject, fieldName)
                      } else {
                          return false
                      }

                      if (typeof value === "object" && value) return Object.keys(value).length
                      const stringToSearch = typeof value === "number" ? value.toString() : value

                      if (getFlagEnabled("WA-8656-fancy-find-signatures")) {
                          if (searchTerm === "__null__") return !stringToSearch
                          if (searchTerm === "__not null__") return !!stringToSearch
                      }

                      return stringToSearch.toLowerCase().includes(searchTerm.toLowerCase())
                  })
        })
    })
}
