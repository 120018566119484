import { createSelector } from "reselect"
import { iCurrentUser, ReduxState } from "../common/types"
import {
    tFilterKey,
    tFilterState,
    tInvalidFilterSelections,
    tSavedFilterSetState,
    tStartOfWeekIndex,
} from "./types"
import { WEEKDAY_INDEX } from "../common/constants"
import { iCompanyGroup } from "../cached-data/types"
import { getFlagEnabled } from "../getFlagValue"

// Select the entire `filters` object in state
export const filtersSelector = (state: ReduxState): tFilterState => state.filters || {}
export const pendingFiltersSelector = (state: ReduxState): tFilterState => state.pendingFilters || {}

export const savedFilterSetsSelector = (state: ReduxState): Array<any> => {
    return (state?.savedFilterSets as tSavedFilterSetState).savedFilterSets ?? []
}

export const activeSavedFilterSelector = (state: ReduxState): any => {
    return (state?.savedFilterSets as tSavedFilterSetState).activeSavedFilter ?? undefined
}

export const savedFilterSetInvalidSelectionsSelector = (
    state: ReduxState
): { [key: string]: tInvalidFilterSelections } => {
    return (state?.savedFilterSets as tSavedFilterSetState).activeSavedFilter?.invalidSelections ?? undefined
}

export const savedFilterSetInvalidFiltersSelector = (state: ReduxState): tFilterKey[] => {
    return (state?.savedFilterSets as tSavedFilterSetState).activeSavedFilter?.invalidFilters ?? undefined
}

export const savedFilterSetErrorSelector = (state: ReduxState): string | undefined => {
    return (state?.savedFilterSets as tSavedFilterSetState).error ?? undefined
}

// Select the date filters.
// TODO: Refactor components that use this selector
export const dateSelector = createSelector(filtersSelector, filters => ({
    start_date: filters.startDate || new Date(),
    end_date: filters.endDate || new Date(),
}))

export const filteredSingleGroupSelector = (state: ReduxState): iCompanyGroup | undefined => {
    const filters = filtersSelector(state)
    if (!filters?.groupId) return

    const groupFilter = filters.groupId
    // We have no groups filtered or more than 1 group filtered
    if (Array.isArray(groupFilter) && groupFilter.length !== 1) return

    const groupId = Array.isArray(groupFilter) ? groupFilter[0] : groupFilter
    const groups = state.entities.companyGroups.objects

    // We have a group ID filtered but it hasn't loaded yet
    if (!(groupId in groups)) return

    return groups[groupId]
}

/**
 * Return the list of currently selected employee IDs
 */
export const filteredEmployeeIdsSelector = createSelector(filtersSelector, filters =>
    Array.isArray(filters.employeeId) ? filters.employeeId : filters.employeeId ? [filters.employeeId] : []
)

/**
 * Since these selectors depend on props, we have to use a slightly awkward
 * factory pattern to get reselect to memoize them correctly.
 *
 * To use these selectors, your component's `mapStateToProps` function will
 * need to look something like this:
 *
 *     const mapStateToProps = () => {
 *         const primaryFilterValueSelector = makePrimaryFilterValueSelector()
 *         const mapStateToProps = (state, props) => ({
 *             primaryFilterValue: primaryFilterValueSelector(state, props)
 *         })
 *         return mapStateToProps
 *     }
 *
 * The reason is that createSelector has a cache size of 1 and only returns the
 * cached value(s) if its arguments are exactly the same. So, if we render
 * `<Foo parameterName="bar">` and then `<Foo parameterName="baz">`, the shared
 * selector based on `props.parameterName` will never recieve the same
 * arguments and we'll never see a cache hit.
 *
 * If `mapStateToProps` returns a function, however, then each instance of the
 * component's container will get its own, private `mapStateToProps` instance
 * with a private selector instance. This allows us to safely share a selector
 * across multiple component instances without breaking memoization.
 */
const _primaryFilterValueSelector = (state: ReduxState, props: { primaryKey: tFilterKey }) =>
    state.pendingFilters[props.primaryKey] || null

export const makePrimaryFilterValueSelector = () => createSelector(_primaryFilterValueSelector, value => value)

const _secondaryFilterValueSelector = (state: ReduxState, props: { secondaryKey: tFilterKey }) =>
    state.pendingFilters[props.secondaryKey] || null

export const makeSecondaryFilterValueSelector = () => createSelector(_secondaryFilterValueSelector, value => value)

interface iFiltersAppliedState {
    initialFiltersApplied: boolean
}

const getInitialFiltersApplied = (state: iFiltersAppliedState): boolean => state.initialFiltersApplied

export const initialFiltersAppliedSelector = createSelector(getInitialFiltersApplied, value => value)

// get the start of the week index for the logged in user - default to sunday
export const startOfTheWeekSelector = (currentUser: iCurrentUser | null): tStartOfWeekIndex => {
    return currentUser?.employee_start_of_week && getFlagEnabled("WA-8094-start-of-week")
        ? (WEEKDAY_INDEX[currentUser.employee_start_of_week] as tStartOfWeekIndex)
        : currentUser?.employee
        ? currentUser.employee.company.start_of_week_index
        : 0
}
