import PivotHeader from "../custom-headers/pivot-header"
import TimecardCellRenderer from "../cell-renderers/timecard-cell-renderer"
import DefaultCellRenderer from "../cell-renderers/default-cell-renderer"
import { pivotValueValueGetter } from "../../../common/ag-grid-value-getters"
import { workShiftDateDefaultTimeValueSetter } from "../../../common/ag-grid-value-setters"
import { canPasteIntoCell, getAvailableStatusValues } from "../../../common/ag-grid-utils"
import { lockedPeriodPivotCellClassRule } from "../../../common/ag-grid-ts-utils"
import { filterFormatter } from "../../../common/ag-grid-value-formatters"
import { statusesByUserRole } from "../../../common/constants"
import { compareGroupColumns, compareTimekeepingEntryObjects } from "../../../common/ag-grid-comparators"
import {
    pivotTotalColumnCustomTooltipMessageBuilder,
    pivotValueColumnCustomTooltipMessageBuilder,
} from "../../../common/ag-grid-custom-tooltip-formatters"
import {
    costCodeColDefCreator,
    dateColDefCreator,
    employeeColDefCreator,
    foremanColDefCreator,
    projectColDefCreator,
    stringColDefCreator,
    timeCardColDefCreator,
    timekeepingStatusColDefCreator,
} from "./standard-col-def-creators"
import {
    classificationFilterDef,
    customTkEntryStatusFilterDef,
    employeeFilterDef,
    laborTypeFilterDef,
    multiCohortFilterDef,
    multiGroupFilterDef,
    multiProjectFilterDef,
    projectStatusFilterDef,
    timeCardOwnerFilterDef,
    tradeFilterDef,
    weekUpdatedNameFilterDef,
} from "../../../filters/filter-defs"
import { getFlagEnabled } from "../../../getFlagValue"
import { loadAllEntities } from "../../../cached-data/actions"
import { selectedCellHasAllSyncedData, selectedCellUnableToTransition } from "../utils"
import {
    getSignatureRequestTooltip,
    getSignatureTooltip,
    singleEmployeeIsSelected,
} from "../../../actions/timekeeping-signatures"
import { filterData } from "../../../common/ag-grid-grouping-utils"
import { getTimekeepingEntryTable } from "./timekeeping-entries"
import { getAbsenceTable } from "./absences"
import { getShiftsAndBreaksTable } from "./shifts-and-breaks"
import { textFieldOptionCreator } from "../../../filters/filter-def-creators"
import { toggleCicoExceptions, toggleHoursExceptions, toggleShiftsBreaksExceptions } from "../../../actions"

const getFindConfigForCustomField = option => {
    const tkSources = ["timekeepingEntries", "absences", "employeeEntries", "ewsStartStopTimes"]
    const sourceField = option.model === "Employee" ? "/employee" : "/project"
    const resource = option.model === "Employee" ? "employees" : "projects"
    return {
        dropdownDisplayName: `${option.model} ${option.label}`,
        textInputPlaceholder: `Find a ${option.label}`,
        resources: tkSources.map(sourceType => ({
            sourceType,
            sourceField,
            reference: {
                resource,
                fields: [`/custom_fields/${option.name}`],
            },
        })),
    }
}

export function getWeeklyTimeCardSettings(featureFlags, currentProject, user, currentProjectIds, sourceData) {
    const userRole = user ? user.user_role : undefined

    const textFieldOptions =
        getFlagEnabled("WA-8402-custom-text-fields") && user && user.text_field_options
            ? user.text_field_options
            : []

    const employeeTextFieldOptionFilterDefs = textFieldOptions
        .filter(option => option.model === "Employee")
        .map(option => textFieldOptionCreator(`Employee ${option.label}`, option.model, option.name))

    const projectTextFieldOptionFilterDefs = textFieldOptions
        .filter(option => option.model === "Project")
        .map(option => textFieldOptionCreator(`Project ${option.label}`, option.model, option.name))

    const cohortTextFieldOptionFilterDefs = textFieldOptions
        .filter(option => option.model === "Cohort")
        .filter(() => getFlagEnabled("WA-8588-cohort-custom-fields"))
        .map(option => textFieldOptionCreator(`Cohort ${option.label}`, option.model, option.name))

    const textFieldFindConfigs = Object.fromEntries(
        textFieldOptions
            .filter(option => ["Employee", "Project"].includes(option.model))
            .map(option => [`${option.model}_${option.name}`, getFindConfigForCustomField(option)])
    )

    const pivotFlex = getFlagEnabled("WA-8428-weekly-view-column-resize") ? { flex: 1 } : {}
    const pivotTotalFlex = getFlagEnabled("WA-8428-weekly-view-column-resize") ? { flex: 1.5 } : {}

    const filters = [
        weekUpdatedNameFilterDef,
        multiGroupFilterDef,
        projectStatusFilterDef,
        multiProjectFilterDef,
        ...projectTextFieldOptionFilterDefs,
        timeCardOwnerFilterDef,
        tradeFilterDef,
        classificationFilterDef,
        ...(featureFlags.labor_type_filter_visible ? [laborTypeFilterDef] : []),
        employeeFilterDef,
        multiCohortFilterDef,
        ...cohortTextFieldOptionFilterDefs,
        ...employeeTextFieldOptionFilterDefs,
        customTkEntryStatusFilterDef,
    ]

    const groupByConfiguration = [
        {
            cols: ["/employee", "/cost_code"],
            label: "Employee",
        },
        {
            cols: ["/foreman", "/employee", "/cost_code"],
            label: "Time Card Owner",
        },
        {
            cols: ["/project", "/foreman", "/employee", "/cost_code"],
            label: "Project",
        },
        {
            cols: ["/classification", "/employee", "/cost_code"],
            label: "Classification",
        },
        {
            cols: ["/trade", "/employee", "/cost_code"],
            label: "Trade",
        },
        {
            cols: ["/status", "/foreman", "/employee", "/cost_code"],
            label: "Status",
        },
        {
            cols: ["/cost_code", "/employee"],
            label: "Cost Code",
        },
        {
            cols: ["/foreman", "/work_shift_id", "/employee", "/cost_code"],
            label: "Time Card",
        },
    ]

    // toggle on or shifts_and_breaks data exists
    const shouldShowShiftsAndBreaks =
        featureFlags.shifts_and_breaks || sourceData?.sourceData?.ewsStartStopTimes?.length

    const employeeSearchFields = getFlagEnabled("WA-8310-fancy-search-full-name")
        ? [
              "/first_name",
              "/last_name",
              "/company_supplied_id",
              "concat(/first_name, /last_name)",
              "concat(/last_name, /first_name)",
          ]
        : ["/first_name", "/last_name", "/company_supplied_id"]

    const cicoResource = featureFlags.cico ? ["timelineEntryVersions"] : []
    const cicoException = {
        label: "CI/CO",
        action: context => {
            const dateCols = context.columnApi.getAllGridColumns()
            // The filter won't fire if we don't do the next step
            context.gridApi.setFilterModel({
                [dateCols.at(-1).getId()]: {},
            })
            return dispatch => dispatch(toggleCicoExceptions())
        },
        activeStateParams: ["cicoExceptions"],
        hasGear: true,
        gearModalType: "cicoException",
        isChild: true,
    }
    const shiftsAndBreaksException = {
        label: "Missing Shifts/Breaks",
        action: context => {
            const dateCols = context.columnApi.getAllGridColumns()
            context.gridApi.setFilterModel({
                [dateCols.at(-1).getId()]: {},
            })
            return dispatch => dispatch(toggleShiftsBreaksExceptions())
        },
        activeStateParams: ["shiftsBreaksExceptions"],
        hasGear: true,
        gearModalType: "shiftsBreaksException",
        isChild: true,
    }
    const customTooltip = featureFlags.cico ? {} : { customTooltip: pivotValueColumnCustomTooltipMessageBuilder }
    const totalCustomTooltip = featureFlags.cico
        ? {}
        : { customTooltip: pivotTotalColumnCustomTooltipMessageBuilder }

    return {
        tableName: "Weekly Time Card Beta",
        navId: "pivot",
        // TODO: rename this to sourceData? row data? rename sourceData to rowData??
        resources: ["timekeepingEntries", "absences", "employeeEntries", "ewsStartStopTimes", ...cicoResource],
        filters,
        additionalQueryParams: { for_dashboard: true },
        forceRefIds: { employees: user ? [user.employee_id] : [] },
        referenceablesWithStringIds: ["timekeepingStatuses"],
        referenceableQueryParamFn: filters => ({
            employees: { cico_start_date: filters.shift_start_time_0, cico_end_date: filters.shift_start_time_1 },
        }),
        relatedNames: {
            timekeepingEntries: {
                companyClassifications: ["/modifier_active/classification"],
                companyTrades: ["/modifier_active/trade"],
                costCodes: ["/cost_code"],
                employees: ["/employee", "/foreman"],
                equipment: ["/modifier_active/equipment"],
                ...(getFlagEnabled("WA-7200-Custom-Modifiers")
                    ? {
                          picklistItems: (user.company_tk_modifiers ?? [])
                              .filter(m => m.standard_tk_modifier_type == null)
                              .map(m => `/modifier_active/${m.slug}`),
                      }
                    : {}),
                projects: ["/project"],
                workShifts: ["/work_shift_id"],
            },
            absences: {
                employees: ["/employee", "/foreman"],
                projects: [],
                companyAbsenceTypes: ["/absence_type"],
                workShifts: ["/work_shift_id"],
            },
            employeeEntries: {
                employees: ["/employee", "/foreman"],
                costCodes: ["/store/code/id"],
                employeeSchemas: ["/schema"],
                equipment: ["/store/equipment/id"],
                projects: ["/project"],
                workShifts: ["/work_shift_id"],
            },
            ewsStartStopTimes: {
                employees: ["/employee", "/foreman"],
                workShifts: ["/work_shift_id"],
                companyStartStopTypes: ["/company_start_stop_type"],
            },
            timelineEntryVersions: {
                employees: ["/employee", "/foreman"],
                projects: ["/project"],
            },
        },
        // TODO:  Potentially have performance issues for companies with a lot of equipment. Needs revisiting.
        initAction: () => dispatch => {
            // Load equipment so that we can load them for the timekeeping modal header
            if (featureFlags.cost_code_level_modifiers && !getFlagEnabled("WA-7952-weekly-view-no-preload"))
                dispatch(loadAllEntities("equipment"))
        },
        colDefs: [
            {
                ...dateColDefCreator(),
                valueSetter: workShiftDateDefaultTimeValueSetter,
                pivot: true,
            },
            timeCardColDefCreator(),
            {
                headerName: "Time Card",
                field: "/work_shift_id",
                referenceableMap: {
                    referenceableKey: "workShifts",
                    rowKey: "workShiftId",
                },
                cellRendererParams: {
                    titleFormatter: params => (params.value ? params.value : "-"),
                },
                cellRenderer: DefaultCellRenderer,
            },
            foremanColDefCreator({
                headerName: "Time Card Owner",
                moreDetail: true,
            }),
            {
                ...employeeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            projectColDefCreator({ moreDetail: true }),
            {
                ...costCodeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            {
                headerName: "Trade",
                field: "/trade",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
                cellRendererParams: {
                    titleFormatter: params => (params.value ? params.value : "-"),
                },
                // TODO: there are other similar features in the pipeline, figure out the best way to
                // handle this as we build those
                filterColumnOf: "employee",
                cellRenderer: DefaultCellRenderer,
                editableValueGetter: params => params.context.referenceableData.employees[params.data.employee],
                filterQueryParam: "employee_trade",
            },
            timekeepingStatusColDefCreator({
                enum: getAvailableStatusValues(userRole),
            }),
            {
                headerName: "Value",
                minWidth: 107,
                // There's a minor change to the default value of the aggregation which helps
                // to determine if nothing was entered for a cell. Putting this change behind
                // a flag since it has such a broad reach
                aggFunc: getFlagEnabled("WA-8656-fancy-find-signatures")
                    ? "aggregateObjectEmptyDefault"
                    : "aggregateObject",
                cellRenderer: TimecardCellRenderer,
                valueGetter: pivotValueValueGetter,
            },
        ],
        gridSettings: {
            pivotHeaderHeight: 60,
            rowHeight: 80,
            pivotMode: true,
            pivotRowTotals: "after",
            autoGroupColumnDef: {
                headerName: "Employee", // might need an OtherSetting to make this based off of vars in the grid
                suppressMenu: true, // this too
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                minWidth: 250,
                storeWidth: getFlagEnabled("WA-8428-weekly-view-column-resize"),
            },
            defaultColDef: {
                getQuickFilterText: filterFormatter,
            },
            rowSelection: "multiple",
            // hide the group header, we don't like it
            pivotGroupHeaderHeight: 0,
            rowBuffer: 40,
        },
        otherSettings: {
            forceWeeklyPivotColumns: true,
            flexColumns: getFlagEnabled("WA-8428-weekly-view-column-resize"),
            pivotColDef: {
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["ST", "OT", "DT"],
                },
                ...customTooltip,
                comparator: compareTimekeepingEntryObjects,
                dayOfWeekHeaderName: true,
                minWidth: 107,
                cellClassRules: {
                    "period-locked": lockedPeriodPivotCellClassRule,
                    readonly: false,
                },
                editable: () => true,
                ...pivotFlex,
            },
            pivotRowTotalColDef: {
                minWidth: 200,
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["All", "ST", "OT", "DT"],
                    classes: ["total-header"],
                },
                headerName: "Total",
                excludeFromFind: true,
                ...pivotTotalFlex,
                ...totalCustomTooltip,
            },
            enableClipboard: true,
            skipLoadOnMount: getFlagEnabled("WA-7952-weekly-view-no-preload"),
            findConfig: {
                // The first set of keys correspond to the dropdown options next to the search bar
                employee: {
                    // We provide a display name for the dropdown option
                    dropdownDisplayName: "Employee",
                    textInputPlaceholder: "Find name or ID#",
                    // list resources which are to be searched in order to find a match
                    // on this type of object.
                    resources: [
                        {
                            // Which type of entity are we looking a?
                            sourceType: "timekeepingEntries",
                            // Which field on this entity type do we look at
                            sourceField: "/employee",
                            // Optionally, we use this field to reference another object (i.e. if the api contains
                            // only the object ID, but we also pulled that related object into the store).
                            // The section below describes which entity type we reference and which fields
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                    ],
                },
                // The first set of keys correspond to the dropdown options next to the search bar
                workshift: {
                    // We provide a display name for the dropdown option
                    dropdownDisplayName: "Time Card ID",
                    textInputPlaceholder: "Find ID#",
                    // list resources which are to be searched in order to find a match
                    // on this type of object.
                    resources: [
                        {
                            // Which type of entity are we looking a?
                            sourceType: "timekeepingEntries",
                            // Which field on this entity type do we look at
                            sourceField: "/work_shift_id",
                            // Optionally, we use this field to reference another object (i.e. if the api contains
                            // only the object ID, but we also pulled that related object into the store).
                            // The section below describes which entity type we reference and which fields
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/work_shift_id",
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/work_shift_id",
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/work_shift_id",
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                    ],
                },
                foreman: {
                    dropdownDisplayName: "Time Card Owner",
                    textInputPlaceholder: "Find name or ID#",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                    ],
                },
                project: {
                    dropdownDisplayName: "Project",
                    textInputPlaceholder: "Find name or job#",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/project",
                            reference: {
                                resource: "projects",
                                fields: ["/job_number", "/name"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/project",
                            reference: {
                                resource: "projects",
                                fields: ["/job_number", "/name"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/project",
                            reference: {
                                resource: "projects",
                                fields: ["/job_number", "/name"],
                            },
                        },
                    ],
                },
                classification: {
                    dropdownDisplayName: "Classification",
                    textInputPlaceholder: "Find a classification",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/modifier_active/classification",
                            reference: {
                                resource: "companyClassifications",
                                fields: ["/code", "/name"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                    ],
                },
                trade: {
                    dropdownDisplayName: "Trade",
                    textInputPlaceholder: "Find a trade",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/modifier_active/trade",
                            reference: {
                                resource: "companyTrades",
                                fields: ["/name", "/code"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                    ],
                },
                status: {
                    dropdownDisplayName: "Status",
                    textInputPlaceholder: "Find a status",
                    enum: statusesByUserRole.map(status => ({ label: status.label, value: status.name })),
                    resources: [
                        // Without the "reference" section, we only search through the sourceField
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/status",
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/status",
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/status",
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/status",
                        },
                    ],
                },
                costCode: {
                    dropdownDisplayName: "Cost Code",
                    textInputPlaceholder: "Find a cost code name or description",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/cost_code",
                            reference: {
                                resource: "costCodes",
                                fields: ["/code", "/description"],
                            },
                        },
                    ],
                },
                absence: {
                    dropdownDisplayName: "Absences",
                    textInputPlaceholder: "Any absence",
                    enumResource: "companyAbsenceTypes",
                    enumField: "/name",
                    // When the category is selected, we automatically filter
                    // out empty values
                    autoFilterEmptyValues: true,
                    resources: [
                        {
                            sourceType: "absences",
                            sourceField: "/absence_type",
                            reference: {
                                resource: "companyAbsenceTypes",
                                fields: ["/name"],
                            },
                        },
                    ],
                },
                shiftExtra: {
                    dropdownDisplayName: "Shift Extras",
                    textInputPlaceholder: "Any shift extra",
                    enumResource: "employeeSchemas",
                    enumField: "/name",
                    // When the category is selected, we automatically filter
                    // out empty values
                    autoFilterEmptyValues: true,
                    resources: [
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/schema",
                            reference: {
                                resource: "employeeSchemas",
                                fields: ["/name"],
                            },
                        },
                    ],
                },
                ...(shouldShowShiftsAndBreaks
                    ? {
                          ewsStartStopTime: {
                              dropdownDisplayName: "Shifts and Breaks",
                              textInputPlaceholder: "Worker/Time Card Owner name, Shift, Break, Meal, etc.",
                              autoFilterEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "ewsStartStopTimes",
                                      sourceField: "/start_stop_type",
                                  },
                                  {
                                      sourceType: "ewsStartStopTimes",
                                      sourceField: "/foreman",
                                      reference: {
                                          resource: "employees",
                                          fields: ["/first_name", "/last_name", "/company_supplied_id"],
                                      },
                                  },
                                  {
                                      sourceType: "ewsStartStopTimes",
                                      sourceField: "/employee",
                                      reference: {
                                          resource: "employees",
                                          fields: ["/first_name", "/last_name", "/company_supplied_id"],
                                      },
                                  },
                              ],
                          },
                      }
                    : {}),
                ...(featureFlags.cost_code_level_modifiers
                    ? {
                          modifier: {
                              dropdownDisplayName: "Modifiers",
                              textInputPlaceholder: "Any modifiers...",
                              textInputDisabled: true,
                              // When the category is selected, we automatically filter
                              // out empty values
                              autoFilterEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "timekeepingEntries",
                                      sourceField: "/modifier_active",
                                  },
                              ],
                          },
                      }
                    : {}),
                ...(featureFlags.timekeeping_comments
                    ? {
                          comment: {
                              dropdownDisplayName: "Comment",
                              textInputPlaceholder: "Any comment",
                              // When the category is selected, we automatically filter
                              // out empty values
                              autoFilterEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "timekeepingEntries",
                                      sourceField: "/comment",
                                  },
                              ],
                          },
                      }
                    : {}),
                ...textFieldFindConfigs,
                ...(getFlagEnabled("WA-8656-fancy-find-signatures")
                    ? {
                          signature: {
                              dropdownDisplayName: "Signature",
                              options: [
                                  { label: "Unsigned", value: "__null__" },
                                  { label: "Signed", value: "__not null__" },
                              ],
                              keepEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "timekeepingEntries",
                                      sourceField: "/signature",
                                  },
                              ],
                          },
                      }
                    : {}),
            },
            groupBy: groupByConfiguration,
            buttons: {
                cell: [
                    {
                        label: "Update Status ▾",
                        icon: "status",
                        action: "updateTimekeepingStatusForFocusedPivotCell",
                        // If all TK entries have Synced status, disable the action and show a tooltip on hover.
                        disabled: context =>
                            selectedCellHasAllSyncedData(context) ||
                            (getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                                selectedCellUnableToTransition(context)),
                        tooltip: context =>
                            selectedCellHasAllSyncedData(context)
                                ? "Entries with a Synced status may only be updated through the Public API."
                                : getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                                  selectedCellUnableToTransition(context)
                                ? "No transitions available for current selection."
                                : undefined,
                    },
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedPivotCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedPivotCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedPivotCell",
                        disabled: context =>
                            getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock")
                                ? !canPasteIntoCell(context).canPaste
                                : !canPasteIntoCell(context),
                        tooltip: context =>
                            getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock")
                                ? canPasteIntoCell(context).tooltip
                                : canPasteIntoCell(context)
                                ? ""
                                : "You do not have permission to overwrite the contents of this cell",
                    },
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteFocusedPivotCell",
                    },
                    {
                        label: "History",
                        icon: "history",
                        action: "openHistoryModalForFocusedPivotCell",
                    },
                    {
                        label: "Duplicate",
                        icon: "copy",
                        action: "duplicateWeeklyViewCell",
                        disabled: context =>
                            Object.values(context.selectedCellData).reduce((acc, el) => acc + el.length, 0) === 0,
                        tooltip: context =>
                            Object.values(context.selectedCellData).reduce((acc, el) => acc + el.length, 0) === 0
                                ? "Selected cell has no data to duplicate"
                                : "Duplicate selected cell's data to a new date",
                    },
                    ...(getFlagEnabled("WA-5644-tk-signatures") && !featureFlags.disable_web_signature_flow
                        ? [
                              {
                                  label: "Sign",
                                  icon: "signature",
                                  action: "getSignatureOptions",
                                  disabled: !singleEmployeeIsSelected,
                                  tooltip: getSignatureTooltip,
                              },
                          ]
                        : []),
                    ...(getFlagEnabled("WA-5644-tk-signatures") &&
                    featureFlags.text_for_signature &&
                    !featureFlags.disable_web_signature_flow
                        ? [
                              {
                                  label: "Request Signature",
                                  icon: "share",
                                  action: "getSignatureRequestOptions",
                                  tooltip: getSignatureRequestTooltip,
                              },
                          ]
                        : []),
                ],
                row: [],
                table: [
                    {
                        label: "Expand/Collapse All",
                        action: "toggleRowExpansion",
                        icon: "expand",
                    },
                    {
                        label: "Group By",
                        action: "groupBy",
                        icon: "group",
                    },
                    {
                        label: "Find",
                        action: "toggleFancySearchBar",
                        icon: "find",
                        customButtonType: "find",
                        activeClass: "fancySearchBarEnabled",
                    },
                    {
                        separator: true,
                    },
                    {
                        label: "Create Time Card",
                        action: "openCreateTimeCardModal",
                        icon: "add",
                    },
                    {
                        label: "Add Entry",
                        action: "openAddTimekeepingEntryModal",
                        icon: "add",
                    },
                    {
                        label: "Update All Statuses ▾",
                        icon: "status",
                        action: "updateTimekeepingStatusForTable",
                        disabled: context =>
                            getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                            selectedCellUnableToTransition({
                                selectedCellData: filterData(sourceData.sourceData, context),
                                currentUser: user,
                            }),
                        tooltip: context =>
                            getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                            selectedCellUnableToTransition({
                                selectedCellData: filterData(sourceData.sourceData, context),
                                currentUser: user,
                            })
                                ? "No transitions available for current selection."
                                : undefined,
                    },
                    {
                        separator: true,
                    },
                    ...(getFlagEnabled("WA-8640-fancy-csv-exporter")
                        ? [
                              {
                                  label: "Export ▾",
                                  icon: "export",
                                  export: true,
                              },
                          ]
                        : [
                              {
                                  label: "Export",
                                  icon: "export",
                                  action: "navigateTo",
                                  args: {
                                      url: "/rhumbix/export/csv/",
                                  },
                              },
                          ]),
                    ...(getFlagEnabled("WA-8536-cico-exceptions")
                        ? [
                              {
                                  label: "Exceptions ▾",
                                  icon: "exception",
                                  args: {
                                      extraArgs: {
                                          options: [
                                              {
                                                  label: "Exceptions",
                                                  action: () => {
                                                      return dispatch => {
                                                          if (featureFlags.cico) dispatch(toggleCicoExceptions())
                                                          dispatch(toggleHoursExceptions())
                                                          dispatch(toggleShiftsBreaksExceptions())
                                                      }
                                                  },
                                                  activeStateParams: featureFlags.cico
                                                      ? [
                                                            "cicoExceptions",
                                                            "hoursExceptions",
                                                            "shiftsBreaksExceptions",
                                                        ]
                                                      : ["hoursExceptions", "shiftsBreaksExceptions"],
                                                  hasGear: false,
                                              },
                                              ...(featureFlags.cico ? [cicoException] : []),
                                              {
                                                  label: "Irregular Hours",
                                                  action: context => {
                                                      const dateCols = context.columnApi.getAllGridColumns()
                                                      context.gridApi.setFilterModel({
                                                          [dateCols.at(-1).getId()]: {},
                                                      })
                                                      return dispatch => dispatch(toggleHoursExceptions())
                                                  },
                                                  activeStateParams: ["hoursExceptions"],
                                                  hasGear: true,
                                                  gearModalType: "hoursException",
                                                  isChild: true,
                                              },
                                              ...(getFlagEnabled("WA-8656-fancy-find-signatures") &&
                                              shouldShowShiftsAndBreaks
                                                  ? [shiftsAndBreaksException]
                                                  : []),
                                          ],
                                      },
                                  },
                                  checklist: true,
                              },
                          ]
                        : []),
                ],
            },
            editablePivotSourceModal: {
                includeTablesForAllSchemas: true,
                tableList: [
                    getTimekeepingEntryTable(featureFlags, currentProject, user, currentProjectIds, sourceData),
                    getAbsenceTable(featureFlags, currentProject, user),
                    ...(shouldShowShiftsAndBreaks
                        ? [getShiftsAndBreaksTable(featureFlags, currentProject, user)]
                        : []),
                ],
            },
            enableSearchBar: false,
            enableFancySearchBar: true,
            exceptionFilters: getFlagEnabled("WA-8536-cico-exceptions")
                ? featureFlags.cico
                    ? ["cicoExceptions", "hoursExceptions", "shiftsBreaksExceptions"]
                    : ["hoursExceptions", "shiftsBreaksExceptions"]
                : [],
            enableAuditHistory: true,
            enableFindMatchRule: true,
        },
    }
}
